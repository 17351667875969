exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-conteudo-index-tsx": () => import("./../../../src/pages/conteudo/index.tsx" /* webpackChunkName: "component---src-pages-conteudo-index-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-2-tsx": () => import("./../../../src/pages/index2.tsx" /* webpackChunkName: "component---src-pages-index-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-agronegocio-index-mdx": () => import("./../../../src/pages/industries/agronegocio/index.mdx" /* webpackChunkName: "component---src-pages-industries-agronegocio-index-mdx" */),
  "component---src-pages-industries-financas-index-mdx": () => import("./../../../src/pages/industries/financas/index.mdx" /* webpackChunkName: "component---src-pages-industries-financas-index-mdx" */),
  "component---src-pages-industries-governo-index-mdx": () => import("./../../../src/pages/industries/governo/index.mdx" /* webpackChunkName: "component---src-pages-industries-governo-index-mdx" */),
  "component---src-pages-industries-industria-index-mdx": () => import("./../../../src/pages/industries/industria/index.mdx" /* webpackChunkName: "component---src-pages-industries-industria-index-mdx" */),
  "component---src-pages-industries-logistica-index-mdx": () => import("./../../../src/pages/industries/logistica/index.mdx" /* webpackChunkName: "component---src-pages-industries-logistica-index-mdx" */),
  "component---src-pages-industries-servicos-index-mdx": () => import("./../../../src/pages/industries/servicos/index.mdx" /* webpackChunkName: "component---src-pages-industries-servicos-index-mdx" */),
  "component---src-pages-industries-varejo-index-mdx": () => import("./../../../src/pages/industries/varejo/index.mdx" /* webpackChunkName: "component---src-pages-industries-varejo-index-mdx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-login-companies-error-index-tsx": () => import("./../../../src/pages/login/companies/error/index.tsx" /* webpackChunkName: "component---src-pages-login-companies-error-index-tsx" */),
  "component---src-pages-login-companies-index-tsx": () => import("./../../../src/pages/login/companies/index.tsx" /* webpackChunkName: "component---src-pages-login-companies-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-success-index-tsx": () => import("./../../../src/pages/login/success/index.tsx" /* webpackChunkName: "component---src-pages-login-success-index-tsx" */),
  "component---src-pages-login-validate-code-index-tsx": () => import("./../../../src/pages/login/validateCode/index.tsx" /* webpackChunkName: "component---src-pages-login-validate-code-index-tsx" */),
  "component---src-pages-modulos-analytics-index-mdx": () => import("./../../../src/pages/modulos/analytics/index.mdx" /* webpackChunkName: "component---src-pages-modulos-analytics-index-mdx" */),
  "component---src-pages-modulos-denuncia-index-mdx": () => import("./../../../src/pages/modulos/denuncia/index.mdx" /* webpackChunkName: "component---src-pages-modulos-denuncia-index-mdx" */),
  "component---src-pages-modulos-desempenho-index-mdx": () => import("./../../../src/pages/modulos/desempenho/index.mdx" /* webpackChunkName: "component---src-pages-modulos-desempenho-index-mdx" */),
  "component---src-pages-modulos-feedback-index-mdx": () => import("./../../../src/pages/modulos/feedback/index.mdx" /* webpackChunkName: "component---src-pages-modulos-feedback-index-mdx" */),
  "component---src-pages-modulos-index-tsx": () => import("./../../../src/pages/modulos/index.tsx" /* webpackChunkName: "component---src-pages-modulos-index-tsx" */),
  "component---src-pages-modulos-metas-index-mdx": () => import("./../../../src/pages/modulos/metas/index.mdx" /* webpackChunkName: "component---src-pages-modulos-metas-index-mdx" */),
  "component---src-pages-modulos-pulse-index-mdx": () => import("./../../../src/pages/modulos/pulse/index.mdx" /* webpackChunkName: "component---src-pages-modulos-pulse-index-mdx" */),
  "component---src-pages-modulos-reconhecimento-index-mdx": () => import("./../../../src/pages/modulos/reconhecimento/index.mdx" /* webpackChunkName: "component---src-pages-modulos-reconhecimento-index-mdx" */),
  "component---src-pages-modulos-recrutamento-index-mdx": () => import("./../../../src/pages/modulos/recrutamento/index.mdx" /* webpackChunkName: "component---src-pages-modulos-recrutamento-index-mdx" */),
  "component---src-pages-modulos-social-index-mdx": () => import("./../../../src/pages/modulos/social/index.mdx" /* webpackChunkName: "component---src-pages-modulos-social-index-mdx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-parceria-tsx": () => import("./../../../src/pages/parceria.tsx" /* webpackChunkName: "component---src-pages-parceria-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-promo-covid-19-um-mes-index-tsx": () => import("./../../../src/pages/promo/covid-19-um-mes/index.tsx" /* webpackChunkName: "component---src-pages-promo-covid-19-um-mes-index-tsx" */),
  "component---src-pages-promo-covid-index-tsx": () => import("./../../../src/pages/promo/covid/index.tsx" /* webpackChunkName: "component---src-pages-promo-covid-index-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

